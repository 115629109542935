/* esversion: 6 */

export function DommerClassInfo(id, datasource_url) {

    return dk.table.ResultSet.create_on(id, {
        dataset: dk.data.DataSet.create({
            datasource: datasource_url,
            pagesize: 20,
        }),

        command_buttons: {
            add: false,
            csv: {
                filename: 'dommerclass.csv',
            }
        },
        
        construct_table: function (location, downloadwidget, ds) {
            dk.info("constructing table...", location, downloadwidget);
            return dk.DataTable.create_on(location, {
                classes: ['table table-bordered table-hover table-condensed'],
                table_data: ds,
                columns: {
                    teacher_email: {label: 'Instruktør e-post'},
                    class_expires: {label: 'Utløpsdato'},
                    student_count: {label: 'Antall planlagt'},
                    students: {label: 'Registrerte'},
                }
            });
        }
    });
}
