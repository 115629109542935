/* esversion: 6 */

class FilterPanel extends dk.panel.PanelWidget {
    constructor(...args) {
        super({
            direction: 'left',
            title: 'Filter',
            dataset: null,
        }, ...args);
    }
    // type: 'FilterPanel3',

    construct_panel_body(body) {
        const self = this;
        const filterbox = dk.$('<div/>');
        body.append(filterbox);

        this.datafilter = dk.filter.DataFilter.create_on(filterbox, {
            structure: {
                content: {}
            },
            dataset: self.dataset,
            filters: {
                krets: {
                    label: 'Krets',
                    select_multiple: false
                }
            }
        });
        return body;
    }
}

export function TopicResult(id, datasource_url) {

    return dk.table.ResultSet.create_on(id, {
        dataset: dk.data.DataSet.create({
            datasource: datasource_url,
            pagesize: 20,
        }),

        command_buttons: {
            add: false,
            csv: {
                filename: 'topicresults.csv',
            }
        },

        construct_filter: function (location, dataset) {
            return FilterPanel.create(location, {
                dataset: dataset
            });
        },

        construct_table: function (location, downloadwidget, ds) {
            dk.info("constructing table...", location, downloadwidget);
            return dk.DataTable.create_on(location, {
                classes: ['table table-bordered table-hover table-condensed'],
                table_data: ds,
                columns: {
                    dommertype: {
                        label: 'Dommertype'
                    },
                    dommer_count: {
                        label: 'Antall',
                        format: function (val, rec, cell) {
                            cell.css('text-align', 'right');
                            return val;
                        }
                    },
                    topic1: {
                        format: function (val, rec, cell) {
                            cell.css('text-align', 'right');
                            return val;
                        }
                    },
                    topic2: {
                        format: function (val, rec, cell) {
                            cell.css('text-align', 'right');
                            return val;
                        }
                    },
                    topic3: {
                        format: function (val, rec, cell) {
                            cell.css('text-align', 'right');
                            return val;
                        }
                    },
                    topic4: {
                        format: function (val, rec, cell) {
                            cell.css('text-align', 'right');
                            return val;
                        }
                    },
                    topic5: {
                        format: function (val, rec, cell) {
                            cell.css('text-align', 'right');
                            return val;
                        }
                    },
                    topic6: {
                        format: function (val, rec, cell) {
                            cell.css('text-align', 'right');
                            return val;
                        }
                    },
                    topic7: {
                        format: function (val, rec, cell) {
                            cell.css('text-align', 'right');
                            return val;
                        }
                    }
                }
            });
        }
    });
}
