/* esversion: 6 */

export function DommerAnsvarligeInfo(id, datasource_url) {

    return dk.table.ResultSet.create_on(id, {
        dataset: dk.data.DataSet.create({
            datasource: datasource_url,
            pagesize: 20,
        }),

        command_buttons: {
            add: false,
            csv: {
                filename: 'ansvarlige.csv',
            }
        },
        
        construct_table: function (location, downloadwidget, ds) {
            dk.info("constructing table...", location, downloadwidget);
            return dk.DataTable.create_on(location, {
                classes: ['table table-bordered table-hover table-condensed'],
                table_data: ds,
                columns: {
                    userid: {
                        label: 'ID',
                        format: function (val) {
                            if (val[1]) {
                                return `<a href="/admin/fotballdommer/dommeransvarlig/${val[0]}/">${val[0]}</a>`;
                            } else {
                                return val[0];
                            }
                        }},
                    username: {label: 'Brukernavn'},
                    full_name: {label: 'Navn'},
                    email: {label: 'E-post'},
                    krets: {label: 'Krets'},
                }
            });
        }
    });
}
