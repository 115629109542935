/* esversion: 6 */

class FilterPanel extends dk.panel.PanelWidget {
    constructor(...args) {
        super({
            direction: 'left',
            title: 'Filter',
            dataset: null,
        }, ...args);
    }
    // type: 'FilterPanel3',

    construct_panel_body(body) {
        const self = this;
        const filterbox = dk.$('<div/>');
        body.append(filterbox);

        this.datafilter = dk.filter.DataFilter.create_on(filterbox, {
            structure: {
                content: {}
            },
            dataset: self.dataset,
            filters: {
                taken: {
                    label: 'Tatt dommertesten',
                    values: {
                        true: 'Ja',
                        false: 'Nei'
                    }
                },
                result: {
                    label: 'Bestått dommertesten',
                    values: {
                        true: 'Ja',
                        false: 'Nei'
                    }
                },
                registered_this_year: {
                    label: 'Registrert i år',
                    values: {
                        true: 'Ja',
                        false: 'Nei'
                    }
                },
                registration_sent: {
                    label: 'Registrering sendt',
                    values: {
                        true: 'Ja',
                        false: 'Nei'
                    }
                },
                type: {
                    label: 'Dommertype',
                    select_multiple: true
                },
                krets: {
                    label: 'Krets',
                    select_multiple: true
                }
            }
        });
        return body;
    }
}


export function DommerList(id, datasource_url, dommertyper) {

    return dk.table.ResultSet.create_on(id, {
        dataset: dk.data.DataSet.create({
            datasource: datasource_url,
            pagesize: 20,
        }),

        command_buttons: {
            add: false,
            csv: {
                filename: 'dommere.csv',
            }
        },

        construct_filter: function (location, dataset) {
            return FilterPanel.create(location, {
                dataset: dataset
            });
        },

        construct_table: function (location, downloadwidget, ds) {
            dk.info("constructing table for DommerList...", location, downloadwidget);
            return dk.DataTable.create_on(location, {
                classes: ['table table-bordered table-hover table-condensed'],
                table_data: ds,
                columns: {
                    dommerid: {
                        label: 'Dommer id',
                        format: function (val) {
                            return `<a href="/profile/nff-${val}/">${val}</a>`;
                        }
                    },
                    dommernavn: {label: 'Navn'},
                    cell_phone: {label: 'Mobil'},
                    email: {label: 'E-post'},
                    dommertype: {
                        label: 'Dommertype',
                        format: function (val, rec, cell) {
                            const $select = $(`<select pk='${rec.pk}' class="dtype"/>`);
                            const options = dommertyper.map(([id, name]) => {
                                if (val === id) {
                                    return `<option selected value=${id}>${name}</option>`;
                                } else {
                                    return `<option value=${id}>${name}</option>`;
                                }
                            });
                            $select.append(options.join('\n'));
                            cell.append($select);
                        }
                    },
                    passed: {
                        label: 'Bestått',
                        format: function(val) {
                            if (val === null) return '--';
                            if (val) {
                                return '<a><img alt="prøven bestått" src="https://static.datakortet.no/ikn/cq/16x16/accept.png"></a>';
                            } else {
                                return '<a><img alt="prøven ikke bestått" src="https://static.datakortet.no/ikn/cq/16x16/delete.png"></a>';
                            }
                        }
                    },
                    best_result: {label: 'Beste resultat'},
                    tries_left: {
                        label: 'Gjenstående forsøk',
                        format: function (val, rec, cell) {
                            if (val[0] === 0) {
                                return `<button type ="button" class="triesbutton btn btn-primary btn-xs" pk=${rec.pk} value=${val[2]}>Gi 3 forsøk</button>`;
                            } else {
                                return val[0];
                            }
                        }
                    },
                    registered_this_year: {
                        label: 'Registrert i år',
                        format: function(val) {
                            if (val) {
                                return '<a><img alt="registrert i år" src="https://static.datakortet.no/ikn/cq/16x16/accept.png"></a>';
                            } else {
                                return '<a><img alt="ikke registrert i år" src="https://static.datakortet.no/ikn/cq/16x16/delete.png"></a>';
                            }
                        }
                    },
                    registration_sent: {
                        label: 'Registrering sendt',
                        format: function(val) {
                            if (val) {
                                return '<a><img alt="registrering sendt" src="https://static.datakortet.no/ikn/cq/16x16/accept.png"></a>';
                            } else {
                                return '<a><img alt="registrering ikke sendt" src="https://static.datakortet.no/ikn/cq/16x16/delete.png"></a>';
                            }
                        }
                    },
                    active: {
                        label: 'Sett inaktiv',
                        format: function (val, rec, cell) {
                            const $box = $(`<input class="setactive" type="checkbox" name='${rec.pk}' value='${val}'>`);
                            cell.append($box);
                        }
                    }

                }
            });
        }
    });
}

dk.ready(() => {

    dk.$('#dommere').on("change", '.dtype', function() {
        dk.json({
            url: "type-change/" + dk.$(this).attr('pk') + "/?val=" + dk.$(this).val()
        })
    });
    dk.$('#dommere').on("click", '.triesbutton', function() {
        const td = dk.$(this).parent();
        dk.json({
            url: "give-attempts/" + dk.$(this).attr('pk') + "/?val=" + dk.$(this).val(),
            success: function success(val) {
                if (val.result === 'success') {
                    td.find('button').remove();
                    td.text(val.attempts);
                }
            }
        });
    });
    dk.$('#dommere').on("click", '.setactive', function() {
        dk.json({
            url: "set-inactive/" + dk.$(this).attr('name') + "/"
        })
    });

});
