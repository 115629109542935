// esversion: 6

import {Gauge} from "./gauge";
import {DommerList} from "./dommere-datasource";
import {RektruttList} from "./rekrutter-datasource";
import {KretsInfo} from "./kretsinfo-datasource";
import {DommerAnsvarligeInfo} from "./ansvarlige-datasource";
import {DommerClassInfo} from "./dommerclass-datasource";
import {TopicResult} from "./topicresult-datasource";
import {DommertypeInfo} from "./dommertype-datasource.js";
import {InactiveDommerList} from "./inactive-dommere-ds";


const fotballdommer = {
    Gauge,
    DommerList,
    RektruttList,
    KretsInfo,
    DommerAnsvarligeInfo,
    DommerClassInfo,
    TopicResult,
    DommertypeInfo,
    InactiveDommerList
};

export default fotballdommer;
