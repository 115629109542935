/* esversion: 6 */

export function KretsInfo(id, datasource_url) {

    return dk.table.ResultSet.create_on(id, {
        dataset: dk.data.DataSet.create({
            datasource: datasource_url,
            pagesize: 20,
        }),

        command_buttons: {
            add: false,
            csv: {
                filename: 'kretsinfo.csv',
            }
        },
        

        construct_table: function (location, downloadwidget, ds) {
            dk.info("constructing table...", location, downloadwidget);
            return dk.DataTable.create_on(location, {
                classes: ['table table-bordered table-hover table-condensed'],
                table_data: ds,
                columns: {
                    id: {label: 'ID',
                        format: function (val) {
                            if (val[1]) {
                                return `<a href="/admin/fotballdommer/fotballkrets/${val[0]}/">${val[0]}</a>`;
                            } else {
                                return val[0];
                            }
                        }},
                    short_name: {label: 'Krets nr'},
                    name: {label: 'Navn'},
                    cell_phone: {label: 'Mobil'},
                    email: {label: 'E-post'},
                    street: {label: 'Adresse'},
                    postnr: {label: 'Postnummer'},
                    poststed: {label: 'Sted'},
                    prod_date: {label: 'Produksjonsdato', format: dk.format.no_date},
                    send_cards_private: {
                        label: 'Kort sendes privat',
                        format: function(val) {
                            if (val) {
                                return '<a><img alt="kort sendes privat" src="https://static.datakortet.no/ikn/cq/16x16/accept.png"></a>';
                            }
                        }
                    },
                    count: {label: 'Registrerte i år'},
                }
            });
        }
    });
}
