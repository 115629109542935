/* esversion: 6 */

class FilterPanel extends dk.panel.PanelWidget {
    constructor(...args) {
        super({
            direction: 'left',
            title: 'Filter',
            dataset: null,
        }, ...args);
    }
    // type: 'FilterPanel3',

    construct_panel_body(body) {
        const self = this;
        const filterbox = dk.$('<div/>');
        body.append(filterbox);

        this.datafilter = dk.filter.DataFilter.create_on(filterbox, {
            structure: {
                content: {}
            },
            dataset: self.dataset,
            filters: {
                krets: {
                    label: 'Krets',
                    select_multiple: true
                }
            }
        });
        return body;
    }
}

export function InactiveDommerList(id, datasource_url) {

    return dk.table.ResultSet.create_on(id, {
        dataset: dk.data.DataSet.create({
            datasource: datasource_url,
            pagesize: 20,
        }),

        command_buttons: {
            add: false,
            csv: {
                filename: 'ikkeaktivedommere.csv',
            }
        },

        construct_filter: function (location, dataset) {
            return FilterPanel.create(location, {
                dataset: dataset
            });
        },

        construct_table: function (location, downloadwidget, ds) {
            dk.info("constructing table for InactiveDommerList...", location, downloadwidget);
            return dk.DataTable.create_on(location, {
                classes: ['table table-bordered table-hover table-condensed'],
                table_data: ds,
                columns: {
                    dommerid: {
                        label: 'Dommer id',
                        format: function (val) {
                            return `<a href="/profile/nff-${val}/">${val}</a>`;
                        }
                    },
                    dommernavn: {label: 'Navn'},
                    cell_phone: {label: 'Mobil'},
                    email: {label: 'E-post'},
                    dommertype: {label: 'Dommertype'},
                    last_registered: {label: 'Sist registrert', format: dk.format.no_date},
                    active: {
                        label: 'Sett aktiv',
                        format: function (val, rec, cell) {
                            const $box = $(`<input class="setactive" type="checkbox" name='${rec.pk}' value='${val}'>`);
                            cell.append($box);
                        }
                    }
                }
            });
        }
    });
}

dk.ready(() => {
    dk.$('#inactive-dommere').on("click", '.setactive', function() {
        dk.json({
            url: "set-active/" + dk.$(this).attr('name') + "/"
        })
    });
});
